var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "search_crumbs" }, [
    _c(
      "a",
      { staticClass: "search_all", on: { click: _vm.handleSearchAll } },
      [
        _vm._v(" 全部商品 "),
        _c("icon-font", {
          staticStyle: {
            "font-size": "12px",
            margin: "0 8px",
            color: "#BCBCBC"
          },
          attrs: { type: "icon-youjiantou" }
        })
      ],
      1
    ),
    _c(
      "ul",
      { staticClass: "crumbs_list" },
      _vm._l(_vm.list, function(item) {
        return _c(
          "li",
          {
            key: item.id,
            on: {
              click: function() {
                return _vm.handleClick(item)
              }
            }
          },
          [
            _c("span", [_vm._v(_vm._s(item.label) + "：")]),
            _c("span", [_vm._v(_vm._s(item.name))]),
            _c("IconFont", {
              staticStyle: { "font-size": "8px", "margin-left": "5px" },
              attrs: { type: "icon-shachu" }
            })
          ],
          1
        )
      }),
      0
    ),
    _c("div", { staticClass: "search_num" }, [
      _vm.kw ? _c("span", [_vm._v('"' + _vm._s(_vm.kw) + '"')]) : _vm._e(),
      _vm._v(" 共 "),
      _c("span", { staticClass: "num" }, [_vm._v(_vm._s(_vm.num))]),
      _vm._v(" 款相关商品 ")
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }