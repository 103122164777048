var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "cate_container" }, [
    _c("div", { staticClass: "cate_wrap" }, [
      _c("span", [_vm._v(" 类目： ")]),
      _c(
        "ul",
        _vm._l(_vm.list, function(item, index) {
          return _c(
            "li",
            {
              key: item.cid,
              class: {
                active: item.active
              },
              attrs: { "bi-route": "shaixuan" },
              on: {
                click: function() {
                  return _vm.handleCidChange(item.cid)
                }
              }
            },
            [
              _c(
                "a",
                {
                  attrs: {
                    "bi-click": "fenlei",
                    "data-id": item.cid,
                    "data-pingtai": _vm.type
                  }
                },
                [_vm._v(_vm._s(item.cname))]
              )
            ]
          )
        }),
        0
      )
    ]),
    _vm.childData && _vm.childData.length
      ? _c(
          "ul",
          { staticClass: "cate_nav" },
          _vm._l(_vm.childDataList || _vm.childData, function(item, index) {
            return _c(
              "li",
              {
                key: item.subcid,
                class: {
                  active: item.active
                },
                on: {
                  click: function() {
                    return _vm.handleSubcidChange(item.subcid)
                  }
                }
              },
              [
                _c("span", { staticClass: "img" }, [
                  _c("img", {
                    attrs: {
                      src: item.scpic,
                      alt: "",
                      width: "45",
                      height: "45"
                    }
                  })
                ]),
                _c("p", [_vm._v(" " + _vm._s(item.subcname) + " ")])
              ]
            )
          }),
          0
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }