<template>
  <div class="page_container" v-if="total" @click="handleClick">
    <a class="prev_page" data-type="prev">上一页</a>
    <a
      :class="{
        page_item: true,
        active: current == 1,
      }"
      data-type="1"
    >
      1
    </a>
    <a class="page_item morePrev" data-type="prevMore" v-if="current > 6">...</a>
    <!-- 2,3,4,5 -->
    <template v-if="current <= 6">
      <a
        :class="{
          page_item: true,
          active: current == item + 1,
        }"
        v-for="item in (pages > 4 ? 4 : pages - 1)"
        :key="item"
        :data-type="item + 1"
      >
        {{ item + 1 }}
      </a>
    </template>
    <!--  -->
    <template v-if="current >= 6 && current <= pages - 6">
      <a
        :class="{
          page_item: true,
          active: current == item,
        }"
        v-for="item in renderPagesMore6(current)"
        :key="item"
        :data-type="item"
      >
        {{ item }}
      </a>
    </template>
    <template v-if="current > pages - 6 && current >= 6 && pages > 6">
      <a
        :class="{
          page_item: true,
          active: current == item,
        }"
        v-for="item in renderPagesLast()"
        :key="item"
        :data-type="item"
      >
        {{ item }}
      </a>
    </template>
    <a class="page_item moreNext" data-type="nextMore" v-if="current <= pages - 6">
      ...
    </a>
    <a
      :class="{
        page_item: true,
        active: current == pages,
      }"
      :data-type="pages"
      v-if="pages >= 6"
    >
      {{ pages }}
    </a>
    <a class="next_page" data-type='next'>下一页</a>
  </div>
</template>

<script>
export default {
  name: "pagination",
  data() {
    return {};
  },
  props: {
    current: {
      type: Number,
      default: 1,
    },
    pageSize: {
      type: Number,
      default: 100,
    },
    total: {
      type: Number,
      default: 0,
    },
    onChange: {
      type: Function,
      default: () => {},
    },
  },
  computed: {
    pages() {
      if (this.total && this.pageSize) {
        return Math.ceil(this.total / this.pageSize);
      }
      return 1;
    },
  },
  methods: {
    renderPagesMore6(current) {
      return [current - 1, current, current + 1, current + 2];
    },
    renderPagesLast() {
      let list = [];
      for (let i = this.pages - 5; i < this.pages; i++) {
        list.push(i);
      }
      return list;
    },
    handleClick(e) {
      let type = e.target.dataset.type
      if (Number(type)) {
        this.$emit('onchange', Number(type))
      } else if (type == 'prev') {
        this.$emit('onchange', this.current - 1 < 1 ? 1 : this.current - 1 )
      } else if (type == 'next') {
        this.$emit('onchange', this.current + 1 > this.pages ? this.pages : this.current + 1)
      } else if (type == 'nextMore') {
        this.$emit('onchange', this.current + 5 > this.pages ? this.pages : this.current + 5)
      } else if (type == 'prevMore') {
        this.$emit('onchange', this.current - 5 < 1 ? 1 : this.current - 5)
      }

      window.scrollTo({
        top: 0
      })
    },
  },
};
</script>

<style scoped lang="less">
.page_container {
  margin: 0 auto;
  text-align: center;
  padding: 20px 0;
  a {
    display: inline-block;
    margin: 0 2px;
    background-color: #fff;
    position: relative;
    &:hover {
      color: #fff;
      border: 1px solid #e0e0e0;
      background-color: #fe2e54;
    }
  }
  a.morePrev:hover {
    &::after {
      content: '<<';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #fe2e54;
    }
  }
  a.moreNext:hover {
    &::after {
      content: '>>';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-color: #fe2e54;
    }
  }
  a.active {
    color: #fff;
    border: 1px solid #e0e0e0;
    background-color: #fe2e54;
  }
  .prev_page {
    border-radius: 2px;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    width: 66px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #e0e0e0;
    box-shadow: none;
    font-weight: 400;
  }
  .page_item {
    border-radius: 2px;
    box-shadow: none;
    height: 38px;
    line-height: 38px;
    font-weight: 400;
    border: 1px solid #e0e0e0;
    color: #666;
    padding: 0 16px;
  }
  .next_page {
    border-radius: 2px;
    font-size: 12px;
    color: rgba(102, 102, 102, 1);
    width: 66px;
    height: 38px;
    line-height: 38px;
    border: 1px solid #e0e0e0;
    box-shadow: none;
    font-weight: 400;
  }
}
</style>
