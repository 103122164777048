<template>
  <div class="search_crumbs">
    <a class="search_all" @click="handleSearchAll">
      全部商品
      <icon-font
        type="icon-youjiantou"
        style="font-size: 12px;margin: 0 8px;color: #BCBCBC;"
      />
    </a>
    <ul class="crumbs_list">
      <li v-for="item in list" :key="item.id" @click="() => handleClick(item)">
        <span>{{ item.label }}：</span>
        <span>{{ item.name }}</span>
        <IconFont type="icon-shachu" style="font-size: 8px;margin-left: 5px" />
      </li>
    </ul>
    <div class="search_num">
      <span v-if="kw">"{{ kw }}"</span> 共
      <span class="num">{{ num }}</span> 款相关商品
    </div>
  </div>
</template>

<script>
import IconFont from "@/components/IconFont";
export default {
  name: "crumb",
  props: {
    list: [], //筛选活动
    num: "", //筛选后数量
  },
  data() {
    return {
      kw: "",
    };
  },
  mounted() {
    let queryData = { ...this.$route.query };
    if (queryData.kw) {
      this.kw = decodeURIComponent(queryData.kw||'');
    }
  },
  methods: {
    handleClick(item) {
      this.$emit("onClick", item);
    },
    handleSearchAll() {
      this.$router.replace({
        path: "/l?t=100",
      });
      // this.$emit("onSearchAll");
    },
  },
  watch: {
    $route() {
      let queryData = { ...this.$route.query };
      this.kw = decodeURIComponent(queryData.kw||'');
    },
  },
  components: {
    IconFont,
  },
};
</script>

<style scoped lang="less">
@import url("../index.less");
.search_crumbs {
  padding: 13px 0;
  font-size: 12px;
  color: #333;
  display: flex;
  .search_all {
    line-height: 22px;
    height: 22px;
    flex-shrink: 0;
    &:hover {
      color: #fe2e54;
    }
  }
  .crumbs_list {
    display: flex;
    li {
      cursor: pointer;
      height: 18px;
      color: #666;
      line-height: 18px;
      padding: 0 6px 0 8px;
      background: rgba(255, 255, 255, 1);
      border: 1px dotted #8b8b8b;
      margin-right: 10px;
      &:hover {
        border-color: #fe2e54;
        color: #fe2e54;
      }
    }
  }
  .search_num {
    line-height: 22px;
    .num {
      color: #fe2e54;
      margin: 0 2px;
    }
  }
}
</style>
