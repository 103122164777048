<template>
  <div class="cate_container">
    <div class="cate_wrap">
      <span>
        类目：
      </span>
      <ul>
        <li
          v-for="(item, index) in list"
          :key="item.cid"
          @click="() => handleCidChange(item.cid)"
          bi-route="shaixuan"
          :class="{
            active: item.active,
          }"
        >
          <a 
            bi-click="fenlei"
            :data-id="item.cid"
            :data-pingtai="type"
          >{{ item.cname }}</a>
        </li>
      </ul>
    </div>
    <ul class="cate_nav" v-if="childData && childData.length">
      <li
        v-for="(item, index) in childDataList || childData"
        :key="item.subcid"
        @click="() => handleSubcidChange(item.subcid)"
        :class="{
          active: item.active,
        }"
      >
        <span class="img">
          <img :src="item.scpic" alt="" width="45" height="45" />
        </span>
        <p>
          {{ item.subcname }}
        </p>
      </li>
    </ul>
  </div>
</template>

<script>
import { getSuperCategory } from "@/services";
export default {
  name: "category",
  props: {
    activeCid: {
      //当前触发的一级类目
      type: Number,
      default: 0,
    },
    activeSubcid: {
      //当前触发的二级类目
      type: Number,
      default: 0,
    },
    onClick: {
      //类目点击事件
      type: Function,
      default: () => {},
    },
    type:{
      // 平台类型
      type:String,
      default:'taobao'
    }
  },
  data() {
    return {
      list: [],
      childDataList: null,
    };
  },
  computed: {
    childData: {
      get() {
        let obj = this.list.find((item) => item.active);
        if (obj) {
          return obj.subcategories;
        }
        return [];
      },
      set(activeSubcid) {
        let obj = this.list.find((item) => item.active);
        if (obj) {
          let list = obj.subcategories.map((item) => {
            if (item.subcid == activeSubcid) {
              item.active = true;
            } else {
              item.active = false;
            }
            return item;
          });
          this.childDataList = list;
        }
        return [];
      },
    },
  },
  watch: {
    activeCid(state) {
      this.childDataList = null;
      this.list = this.list.map((item) => {
        if (item.cid == state) {
          item.active = true;
        } else {
          item.active = false;
        }
        return item;
      });
    },
    activeSubcid(state) {
      this.childData = state;
    },
  },
  mounted() {
    getSuperCategory().then((res) => {
      if (res && res.code === 0) {
        this.list = res.data;
        if (this.activeCid) {
          this.childDataList = null;
          this.list = this.list.map((item) => {
            if (item.cid == this.activeCid) {
              item.active = true;
            } else {
              item.active = false;
            }
            return item;
          });
        }
        if (this.activeSubcid) {
          this.childData = this.activeSubcid;
        }
      }
    });
  },
  methods: {
    handleSubcidChange(subcid) {
      const obj = {
        type: "subcid",
        value: subcid,
      };
      this.$emit("onClick", obj);
    },
    handleCidChange(cid) {
      const obj = {
        type: "cids",
        value: cid,
      };
      this.$emit("onClick", obj);
    },
  },
};
</script>

<style lang="less" scoped>
.cate_container {
  margin-bottom: 10px;
}

.cate_wrap {
  display: flex;
  background: #fff;
  height: 50px;
  line-height: 50px;
  border: 1px solid rgba(241, 241, 241, 1);
  span {
    display: block;
    width: 80px;
    line-height: 50px;
    color: #999;
    text-align: center;
    margin-right: 30px;
  }
  ul {
    display: flex;
    li {
      cursor: pointer;
      list-style: none;
      padding: 0 15px;
      border-left: 1px solid transparent;
      border-right: 1px solid transparent;
      &:hover a {
        color: #fe2e54;
      }
    }
    li.active {
      border-top: 2px solid #fe2e54;
      border-left: 1px solid #f1f1f1;
      border-right: 1px solid #f1f1f1;
      border-bottom: 1px solid #fff;
      height: 48px;
      line-height: 47px;
    }
  }
}
.cate_nav {
  background: #fff;
  height: 100px;
  padding: 16px 108px;
  box-sizing: border-box;
  border: 1px solid rgba(241, 241, 241, 1);
  border-top: none;
  display: flex;
  li {
    width: 80px;
    text-align: center;
    cursor: pointer;
    .img {
      border-radius: 100%;
      display: inline-block;
      background: #d7e3f7;
      width: 45px;
      height: 45px;
      overflow: hidden;
      margin-bottom: 5px;
    }
    p {
      width: 80px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &:hover {
      color: #fe2e54;
    }
  }
  li.active {
    p {
      color: #fe2e54;
    }
  }
}
</style>
