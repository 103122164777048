var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "today_container", style: { height: _vm.listDom + "px" } },
    [
      _c("div", { staticClass: "bg" }, [
        _c("h2", [_vm._v("为您推荐")]),
        _c(
          "div",
          {
            ref: "listDom",
            staticClass: "item_list",
            attrs: { "bi-route": "tuijian" }
          },
          _vm._l(_vm.data.slice(0, 10), function(item, index) {
            return _c(
              "a",
              {
                key: item.id,
                staticClass: "itemCard",
                attrs: {
                  "data-index": index,
                  "data-gid": item.id || item.goodsId,
                  "data-pingtai": _vm.type,
                  "bi-click": "goods",
                  target: "_blank",
                  href:
                    "?r=l/d&u=1&id=" + (item.id || item.goodsId) + "&from=dtk"
                },
                on: {
                  click: function($event) {
                    return _vm.handleItemToWindow(item)
                  }
                }
              },
              [
                _c("div", { staticClass: "img_area" }, [
                  _c("img", {
                    attrs: { src: item.mainPic + "_310x310.jpg", alt: "" }
                  }),
                  _c("span", { staticClass: "dapai_icon" }, [
                    _vm._v(
                      _vm._s(
                        item.specialType == 2
                          ? "品牌折扣"
                          : item.specialType == 3
                          ? "历史低价"
                          : "限时买送"
                      )
                    )
                  ])
                ]),
                _c("div", { staticClass: "item_bottom" }, [
                  _c(
                    "div",
                    {
                      staticClass: "title",
                      attrs: { title: item.dtitle || item.title }
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: _vm.getShopImg(item.tchaoshi, item.shopType),
                          alt: ""
                        }
                      }),
                      _c("span", [_vm._v(_vm._s(item.dtitle || item.title))])
                    ]
                  ),
                  _c("div", { staticClass: "price" }, [
                    _c("span", { staticClass: "or_price" }, [
                      _vm._v("￥"),
                      _c("b", [_vm._v(_vm._s(item.actualPrice))])
                    ]),
                    _c("span", { staticClass: "ac_price" }, [
                      _vm._v("￥" + _vm._s(item.originalPrice))
                    ])
                  ]),
                  _c("div", { staticClass: "sales_num" }, [
                    _vm._v(
                      "已售" +
                        _vm._s(_vm._f("salesNum")(item.monthSales, _vm._, 1)) +
                        "件"
                    )
                  ])
                ])
              ]
            )
          }),
          0
        )
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }