<template>
  <div class="today_container" :style="{ height: listDom + 'px' }">
    <div class="bg">
      <h2>为您推荐</h2>
      <div bi-route="tuijian" class="item_list" ref="listDom">
        <a
          @click="handleItemToWindow(item)"
          :data-index="index"
          :data-gid="item.id || item.goodsId"
          :data-pingtai="type"
          bi-click="goods"
          class="itemCard"
          target="_blank"
          :href="`?r=l/d&u=1&id=${item.id || item.goodsId}&from=dtk`"
          v-for="(item,index) in data.slice(0, 10)"
          :key="item.id"
        >
          <div class="img_area">
            <img :src="item.mainPic + '_310x310.jpg'" alt="" />
            <span class="dapai_icon">{{
              item.specialType == 2
                ? "品牌折扣"
                : item.specialType == 3
                ? "历史低价"
                : "限时买送"
            }}</span>
          </div>
          <div class="item_bottom">
            <div class="title" :title="item.dtitle || item.title">
              <img :src="getShopImg(item.tchaoshi, item.shopType)" alt="" />
              <span>{{ item.dtitle || item.title }}</span>
            </div>
            <div class="price">
              <span class="or_price"
                >￥<b>{{ item.actualPrice }}</b></span
              >
              <span class="ac_price">￥{{ item.originalPrice }}</span>
            </div>
            <div class="sales_num">已售{{ item.monthSales | salesNum(_,1) }}件</div>
          </div>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: Array,
    type: String
  },
  data() {
    return {
      listDom: 3100,
    };
  },
  mounted() {
    this.$nextTick(() => {
      this.listDom = this.$refs.listDom.clientHeight + 80;
    });
  },
  methods: {
    handleItemToWindow(data) {
      try {
        DTKsatc.eventStat({
          name: "goodsWntjClickEvent",
          params: { desc: "为你推荐模块点击信息", gid: data.goodsId },
        });
      } catch (err) {}
    },
    getShopImg(tchaoshi, shopType) {
      if (tchaoshi == 1)
        return require("../../../assets/search/prod_icon3.png");
      if (shopType == 1)
        return require("../../../assets/search/prod_icon1.png");
      return null;
    },
  },
};
</script>

<style lang="less" scoped>
.today_container {
  width: 230px;
  margin-top: 12px;
  margin-left: 12px;
  background: #ffffff;
  position: relative;
  .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 230px;
    height: 350px;
    background-image: linear-gradient(40deg, #ffffff 27%, #fff0f2 100%);
  }
  h2 {
    font-family: MicrosoftYaHei-Bold;
    font-weight: Bold;
    font-size: 16px;
    color: #333333;
    padding-left: 15px;
  }
  .item_list {
    width: 80%;
    margin: 0 auto;
    padding-top: 15px;
    .itemCard {
      margin-bottom: 28px;
      cursor: pointer;
      display: block;
      &:hover {
        .item_bottom .title span {
          color: #fe2e2e;
        }
      }
      .img_area {
        position: relative;
        .dapai_icon {
          width: 70px;
          height: 20px;
          background-image: linear-gradient(90deg, #ff6594 0%, #ff2049 100%);
          border-radius: 0 0 9.5px 0;
          position: absolute;
          top: -10px;
          left: 0;
          font-family: MicrosoftYaHei;
          font-size: 12px;
          color: #ffffff;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        img {
          width: 190px;
          height: 190px;
        }
      }
      .item_bottom {
        .title {
          width: 100%;
          display: flex;
          align-items: center;
          margin-top: 5px;
          img {
            width: 14px;
            height: 14px;
            margin-right: 4px;
          }
          span {
            display: block;
            font-size: 13px;
            color: #333333;
            font-family: PingFangSC-Regular;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            -webkit-line-clamp: 1;
          }
        }
        .price {
          margin-top: 5px;
          .or_price {
            font-size: 14px;
            color: #fe2e2e;
            b {
              font-weight: 400;
              font-family: ArialMT;
              font-size: 21px;
              color: #fe2e2e;
            }
          }
          .ac_price {
            font-family: ArialMT;
            font-size: 12px;
            color: #ababab;
            text-decoration: line-through;
          }
        }
        .sales_num {
          margin-top: 5px;
          font-family: PingFangSC-Regular;
          font-weight: 400;
          font-size: 12px;
          color: #ababab;
        }
      }
    }
  }
}
</style>